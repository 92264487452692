<template>
  <!-- <div id="invoice"> -->
  <div v-if="show" class="invoice-box" id="invoice" :style="{'padding': '30px 15px','background-color': '#ffffff',
         'direction': (lang == 'ar'? 'rtl': 'ltr'), 'text-align': (lang == 'ar'? 'right': 'left') }">
    <div class="border-content">
      <div>
        <table class="table table-bordered mb-0">
          <thead>
          <tr>
            <th>{{ $t('pos_session.shift') }}</th>
            <th>{{ $t('pos_session.session_id') }}</th>
            <th>{{ $t('pos_session.opened_date') }}</th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td>{{ data.shift_name }}</td>
            <td>{{ data.shift_name }} / {{ data.opened_date }} / {{ data.session_id }}</td>
            <td>{{ data.opened_date }}</td>
          </tr>
          </tbody>
        </table>
      </div>
      <div>
        <table class="table table-bordered mb-0">
          <thead>
          <tr>
            <th>{{ $t('pos_session.device') }}</th>
            <th>{{ $t('pos_session.staff') }}</th>
            <th>{{ $t('pos_session.closed_date') }}</th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td>{{ data.device_name }}</td>
            <td>{{ data.added_by }}</td>
            <td>{{ data.closed_date }}</td>
          </tr>
          </tbody>
        </table>
      </div>
      <div class="bg-cc p-5">
        <h4><span class="box-title"></span> {{ $t('pos_session.summary_by_payment_method') }}</h4>
      </div>
      <div>
        <table class="table table-bordered mb-0">
          <thead>
          <tr>
            <th>{{ $t('pos_session.payment_method') }}</th>
            <th>{{ $t('pos_session.expected_amount') }}</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(row, index) in data.invoices" :key="index">
            <td>{{ row.payment_method_name ? row.payment_method_name : 'Cash' }}</td>
            <td>{{ row.invoice_total }} {{ data.currency_name }}</td>
          </tr>
          </tbody>
          <tfoot>
          <tr>
            <th>{{ $t('pos_session.total') }}</th>
            <td><b>{{ data.total_invoices ? data.total_invoices : '0.00' }} {{ data.currency_name }}</b></td>
          </tr>
          </tfoot>

        </table>
      </div>
      <div class="bg-cc p-5">
        <h4><span class="box-title"></span> {{ $t('pos_session.cash_movements') }}</h4>
      </div>
      <div>
        <table class="table table-bordered mb-0">
          <thead>
          <tr>
            <th>{{ $t('pos_session.time') }}</th>
            <th>{{ $t('pos_session.from_to') }}</th>
            <th>{{ $t('pos_session.amount') }}</th>
            <th>{{ $t('pos_session.note') }}</th>
          </tr>
          </thead>
          <tbody>
          <!--                    <tr v-for="(row, index) in data.cash_movements" :key="index">-->
          <!--                        <td>{{row.payment_method_name ? row.payment_method_name : 'Cash'}}</td>-->
          <!--                        <td>{{row.invoice_total}} {{data.currency_name}}</td>-->
          <!--                        <td>{{row.refund_total ? row.refund_total : '0'}} {{data.currency_name}}</td>-->
          <!--                        <td>{{row.invoice_total}} {{data.currency_name}}</td>-->
          <!--                        <td>{{row.invoice_total}} {{data.currency_name}}</td>-->
          <!--                    </tr>-->
          </tbody>
          <tfoot>
          <tr>
            <th colspan="3">{{ $t('pos_session.total') }}</th>
            <td><b>{{ data.total_cash_movements ? data.total_cash_movements : '0.00' }} {{ data.currency_name }}</b></td>
          </tr>
          </tfoot>

        </table>
      </div>
      <div class="bg-cc p-5">
        <h4><span class="box-title"></span> {{ $t('pos_session.sales_details_by_payment_method') }}</h4>
      </div>
      <div v-if="data.invoices">
        <table class="table table-bordered mb-0">
          <thead>
          <tr>
            <th>{{ $t('pos_session.payment_method') }}</th>
            <th>{{ $t('pos_session.sales') }}</th>
            <th>{{ $t('pos_session.refunds') }}</th>
            <th>{{ $t('pos_session.net') }}</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(row, index) in data.invoices" :key="index">
            <td>{{ row.payment_method_name ? row.payment_method_name : 'Cash' }}</td>
            <td>{{ row.invoice_total }} {{ data.currency_name }}</td>
            <td>{{ row.total_refunds ? row.total_refunds : '0' }} {{ data.currency_name }}</td>
            <td>{{ row.invoice_total }} {{ data.currency_name }}</td>
          </tr>
          </tbody>
          <tfoot>
          <tr>
            <th colspan="3">{{ $t('pos_session.total') }}</th>
            <td><b>{{ data.total_invoices }} {{ data.currency_name }}</b></td>
          </tr>
          </tfoot>

        </table>
      </div>

    </div>
  </div>
  <!-- </div> -->
</template>

<script>
import {jsPDF} from "jspdf";
import html2canvas from "html2canvas";
import ApiService from "@/core/services/api.service";
import jsPdfExport from "@/core/config/jsPdfExport";


export default {
  name: "pos-invoice",

  data() {
    return {
      lang: this.$i18n.locale,
      mainRoute: 'pos/pos-sessions',
      data: {},
      idEditing: this.$route.params.id ? this.$route.params.id : null,
      action: this.$route.params.action ? this.$route.params.action : null,
      show: false,
    }
  },
  methods: {
    printCustomerInvoice() {
      let _id = 'invoice';
      // if (this.action == 'pdf'){
      this.exportPDF(_id);
      // }else{
      //   this.print(_id)
      // }


      // let width = pdf.internal.pageSize.getWidth();
      // let height = pdf.internal.pageSize.getHeight();
      //
      //   // let check_lang = this.lang == 'ar'? 'arabic': 'english';
      //   html2canvas(element).then(canvas => {
      //       let image = canvas.toDataURL('image/png', 1.0);
      //
      //     pdf.addImage(image, 'PNG', 0, 0, width, height);
      //       // pdf.addImage(image, 'PNG', 45, 15);
      //       // pdf.save(`${ 'packing slip '}${check_lang}${'.pdf'}`);
      //       if (this.action == 'print'){
      //           pdf.autoPrint();
      //       }
      //       let _url = pdf.output("bloburl");
      //       location.href = _url;
      //   });
    },
    exportPDF(_id) {
      let that = this;
      // let pdf = jsPDF("p", "px", 'a4');
      //
      // const AmiriRegular = pdf.loadFile("https://rawcdn.githack.com/MrRio/jsPDF/cbc85b4bb66d5c1a2d50d199e8bf33836f2f12d7/test/reference/Amiri-Regular.ttf", true);
      // pdf.addFileToVFS("Amiri-Regular.ttf", AmiriRegular);
      //
      // pdf.addFont("Amiri-Regular.ttf", "Amiri", "normal");
      let pdf = jsPdfExport("p", "px", 'a4');
      pdf.html(document.getElementById(_id), {
        html2canvas: {
          scale: 0.5,
        },
        callback: function (pdf) {
          if (that.action == 'pdf') {
            pdf.save('invoice');
          } else {
            pdf.autoPrint();
            pdf.output('dataurlnewwindow');
          }

          // var iframe = document.createElement('iframe');
          // iframe.setAttribute('style', 'position:absolute;top:0;right:0;height:100%; width:600px');
          // document.body.appendChild(iframe);
          // iframe.src = pdf.output('datauristring');
        }
      });
    },
    // print(_id) {
    //   let newWin = window.open("");
    //   newWin.document.write("<link rel='stylesheet' href='https://cdn.jsdelivr.net/npm/bootstrap@4.0.0/dist/css/bootstrap.min.css'>");
    //
    //   setTimeout(() => {
    //     let divToPrint = document.getElementById(_id);
    //     newWin.document.writeln(divToPrint.outerHTML);
    //
    //     newWin.print();
    //     newWin.close();
    //   }, 100)
    // },
    async getData() {
      let _id = this.idEditing;
      await ApiService.get(`${this.mainRoute}/${_id}`).then((response) => {
        this.data = response.data.data;
        this.show = true;
      });
    },
  },
  mounted() {
    let promise = this.getData();
    Promise.all([promise]).then(() => {
      this.printCustomerInvoice();
    });
    // window.print();
  }
}
</script>

<style>
#invoice {
  font-family: "Amiri";
  width: 235mm;
}

body {
  font-size: 14px;
}

canvas {
  height: 100;
}

.invoice-box {
  background-color: #fff;
  margin: auto;
  padding: 30px;
  border: 1px solid #fff;
  font-size: 16px;
  line-height: 24px;
  font-family: 'Helvetica Neue', 'Helvetica', Helvetica, Arial, sans-serif;
  color: #000;
}

.invoice-box table {
  width: 100%;
  line-height: inherit;
  text-align: left;
}

.invoice-box table td {
  /* padding: 5px; */
  vertical-align: top;
  font-size: 14px;
}

.invoice-box table tr.top td.title {
  font-size: 45px;
  line-height: 45px;
  color: #000;
}

.padd {
  padding-bottom: 100px;

}

.invoice-box table tr.information td {
  padding-bottom: 40px;
}

.invoice-box table tr.heading td {
  border: 1px solid #000 !important;
  background: #CAD3C8;
  border-bottom: 1px solid #fff;
  font-weight: bold;
}

.border {
  border: 1px solid #000 !important;
  padding: 5px;
}

.color {
  background-color: #CAD3C8;
}

.invoice-box table tr.details td {
  padding-bottom: 20px;
}

.invoice-box table tr.item td {
  border-bottom: 1px solid #eee;
}

.invoice-box table tr.item.last td {
  border-bottom: none;
}

.invoice-box table tr.total td:nth-child(2) {
  border-top: 2px solid #eee;
  font-weight: bold;
}

@media only screen and (max-width: 600px) {
  .invoice-box table tr.top table td {
    width: 100%;
    display: block;
    text-align: center;
  }

  .invoice-box table tr.information td {
    width: 100%;
    display: block;
    text-align: center;
  }
}

/** RTL **/

.invoice-box.rtl {
  direction: rtl;
  font-family: Tahoma, 'Helvetica Neue', 'Helvetica', Helvetica, Arial, sans-serif;
}

.invoice-box.rtl table {
  text-align: right;
}

.invoice-box.rtl table tr td:nth-child(2) {
  text-align: left;
}

.table-bordered th, .table-bordered td {
  border: 1px solid #cdcdcd;
}

.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #cdcdcd;
}

.table thead th, .table thead td {
  background-color: #f7f7f7;
}

.table.table-bordered tfoot th, .table.table-bordered tfoot td {
  border-bottom: 1px solid #cdcdcd;
}

.bg-cc {
  background-color: #f7f7f7;
}

.border-content {
  border: 1px solid #cdcdcd;
}

.box-title {
  background-color: #cdcdcd;
  width: 15px;
  height: 5px;
}
</style>